/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBranche = /* GraphQL */ `
  query GetBranche($id: ID!) {
    getBranche(id: $id) {
      id
      nomBranche
      formules {
        items {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBranches = /* GraphQL */ `
  query ListBranches(
    $filter: ModelBrancheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBranches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nomBranche
        formules {
          items {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChamps = /* GraphQL */ `
  query GetChamps($id: ID!) {
    getChamps(id: $id) {
      id
      nom
      valeur
      typeValeur
      regimeId
      regime {
        id
        nomRegime
        optionId
        options {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        champs {
          items {
            id
            nom
            valeur
            typeValeur
            regimeId
            regime {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChamps = /* GraphQL */ `
  query ListChamps(
    $filter: ModelChampsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nom
        valeur
        typeValeur
        regimeId
        regime {
          id
          nomRegime
          optionId
          options {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          champs {
            items {
              id
              nom
              valeur
              typeValeur
              regimeId
              regime {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const champsByRegime = /* GraphQL */ `
  query ChampsByRegime(
    $regimeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChampsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    champsByRegime(
      regimeId: $regimeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nom
        valeur
        typeValeur
        regimeId
        regime {
          id
          nomRegime
          optionId
          options {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          champs {
            items {
              id
              nom
              valeur
              typeValeur
              regimeId
              regime {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormule = /* GraphQL */ `
  query GetFormule($id: ID!) {
    getFormule(id: $id) {
      id
      nomFormule
      brancheId
      branche {
        id
        nomBranche
        formules {
          items {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      options {
        items {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFormules = /* GraphQL */ `
  query ListFormules(
    $filter: ModelFormuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nomFormule
        brancheId
        branche {
          id
          nomBranche
          formules {
            items {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        options {
          items {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forumlesByBranche = /* GraphQL */ `
  query ForumlesByBranche(
    $brancheId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forumlesByBranche(
      brancheId: $brancheId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nomFormule
        brancheId
        branche {
          id
          nomBranche
          formules {
            items {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        options {
          items {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOptions = /* GraphQL */ `
  query GetOptions($id: ID!) {
    getOptions(id: $id) {
      id
      nomOption
      formuleId
      formule {
        id
        nomFormule
        brancheId
        branche {
          id
          nomBranche
          formules {
            items {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        options {
          items {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      regimes {
        items {
          id
          nomRegime
          optionId
          options {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          champs {
            items {
              id
              nom
              valeur
              typeValeur
              regimeId
              regime {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nomOption
        formuleId
        formule {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        regimes {
          items {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const optionsByFormule = /* GraphQL */ `
  query OptionsByFormule(
    $formuleId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    optionsByFormule(
      formuleId: $formuleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nomOption
        formuleId
        formule {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        regimes {
          items {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegime = /* GraphQL */ `
  query GetRegime($id: ID!) {
    getRegime(id: $id) {
      id
      nomRegime
      optionId
      options {
        id
        nomOption
        formuleId
        formule {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        regimes {
          items {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      champs {
        items {
          id
          nom
          valeur
          typeValeur
          regimeId
          regime {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegimes = /* GraphQL */ `
  query ListRegimes(
    $filter: ModelRegimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nomRegime
        optionId
        options {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        champs {
          items {
            id
            nom
            valeur
            typeValeur
            regimeId
            regime {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const regimeByOptions = /* GraphQL */ `
  query RegimeByOptions(
    $optionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRegimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regimeByOptions(
      optionId: $optionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nomRegime
        optionId
        options {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        champs {
          items {
            id
            nom
            valeur
            typeValeur
            regimeId
            regime {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userId
      email
      password
      nom
      prenom
      civilite
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        email
        password
        nom
        prenom
        civilite
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByUserID = /* GraphQL */ `
  query UserByUserID(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        password
        nom
        prenom
        civilite
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPmss = /* GraphQL */ `
  query GetPmss($id: ID!) {
    getPmss(id: $id) {
      id
      valeur
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPmsses = /* GraphQL */ `
  query ListPmsses(
    $filter: ModelPmssFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPmsses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        valeur
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBatchPayload = /* GraphQL */ `
  query GetBatchPayload($id: ID!) {
    getBatchPayload(id: $id) {
      count
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBatchPayloads = /* GraphQL */ `
  query ListBatchPayloads(
    $filter: ModelBatchPayloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchPayloads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        count
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
