/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBranche = /* GraphQL */ `
  mutation CreateBranche(
    $input: CreateBrancheInput!
    $condition: ModelBrancheConditionInput
  ) {
    createBranche(input: $input, condition: $condition) {
      id
      nomBranche
      formules {
        items {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBranche = /* GraphQL */ `
  mutation UpdateBranche(
    $input: UpdateBrancheInput!
    $condition: ModelBrancheConditionInput
  ) {
    updateBranche(input: $input, condition: $condition) {
      id
      nomBranche
      formules {
        items {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBranche = /* GraphQL */ `
  mutation DeleteBranche(
    $input: DeleteBrancheInput!
    $condition: ModelBrancheConditionInput
  ) {
    deleteBranche(input: $input, condition: $condition) {
      id
      nomBranche
      formules {
        items {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChamps = /* GraphQL */ `
  mutation CreateChamps(
    $input: CreateChampsInput!
    $condition: ModelChampsConditionInput
  ) {
    createChamps(input: $input, condition: $condition) {
      id
      nom
      valeur
      typeValeur
      regimeId
      regime {
        id
        nomRegime
        optionId
        options {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        champs {
          items {
            id
            nom
            valeur
            typeValeur
            regimeId
            regime {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChamps = /* GraphQL */ `
  mutation UpdateChamps(
    $input: UpdateChampsInput!
    $condition: ModelChampsConditionInput
  ) {
    updateChamps(input: $input, condition: $condition) {
      id
      nom
      valeur
      typeValeur
      regimeId
      regime {
        id
        nomRegime
        optionId
        options {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        champs {
          items {
            id
            nom
            valeur
            typeValeur
            regimeId
            regime {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChamps = /* GraphQL */ `
  mutation DeleteChamps(
    $input: DeleteChampsInput!
    $condition: ModelChampsConditionInput
  ) {
    deleteChamps(input: $input, condition: $condition) {
      id
      nom
      valeur
      typeValeur
      regimeId
      regime {
        id
        nomRegime
        optionId
        options {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        champs {
          items {
            id
            nom
            valeur
            typeValeur
            regimeId
            regime {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormule = /* GraphQL */ `
  mutation CreateFormule(
    $input: CreateFormuleInput!
    $condition: ModelFormuleConditionInput
  ) {
    createFormule(input: $input, condition: $condition) {
      id
      nomFormule
      brancheId
      branche {
        id
        nomBranche
        formules {
          items {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      options {
        items {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFormule = /* GraphQL */ `
  mutation UpdateFormule(
    $input: UpdateFormuleInput!
    $condition: ModelFormuleConditionInput
  ) {
    updateFormule(input: $input, condition: $condition) {
      id
      nomFormule
      brancheId
      branche {
        id
        nomBranche
        formules {
          items {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      options {
        items {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFormule = /* GraphQL */ `
  mutation DeleteFormule(
    $input: DeleteFormuleInput!
    $condition: ModelFormuleConditionInput
  ) {
    deleteFormule(input: $input, condition: $condition) {
      id
      nomFormule
      brancheId
      branche {
        id
        nomBranche
        formules {
          items {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      options {
        items {
          id
          nomOption
          formuleId
          formule {
            id
            nomFormule
            brancheId
            branche {
              id
              nomBranche
              formules {
                items {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            options {
              items {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          regimes {
            items {
              id
              nomRegime
              optionId
              options {
                id
                nomOption
                formuleId
                formule {
                  id
                  nomFormule
                  brancheId
                  branche {
                    id
                    nomBranche
                    formules {
                      items {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  options {
                    items {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                regimes {
                  items {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              champs {
                items {
                  id
                  nom
                  valeur
                  typeValeur
                  regimeId
                  regime {
                    id
                    nomRegime
                    optionId
                    options {
                      id
                      nomOption
                      formuleId
                      formule {
                        id
                        nomFormule
                        brancheId
                        createdAt
                        updatedAt
                        __typename
                      }
                      regimes {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    champs {
                      items {
                        id
                        nom
                        valeur
                        typeValeur
                        regimeId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOptions = /* GraphQL */ `
  mutation CreateOptions(
    $input: CreateOptionsInput!
    $condition: ModelOptionsConditionInput
  ) {
    createOptions(input: $input, condition: $condition) {
      id
      nomOption
      formuleId
      formule {
        id
        nomFormule
        brancheId
        branche {
          id
          nomBranche
          formules {
            items {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        options {
          items {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      regimes {
        items {
          id
          nomRegime
          optionId
          options {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          champs {
            items {
              id
              nom
              valeur
              typeValeur
              regimeId
              regime {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOptions = /* GraphQL */ `
  mutation UpdateOptions(
    $input: UpdateOptionsInput!
    $condition: ModelOptionsConditionInput
  ) {
    updateOptions(input: $input, condition: $condition) {
      id
      nomOption
      formuleId
      formule {
        id
        nomFormule
        brancheId
        branche {
          id
          nomBranche
          formules {
            items {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        options {
          items {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      regimes {
        items {
          id
          nomRegime
          optionId
          options {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          champs {
            items {
              id
              nom
              valeur
              typeValeur
              regimeId
              regime {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOptions = /* GraphQL */ `
  mutation DeleteOptions(
    $input: DeleteOptionsInput!
    $condition: ModelOptionsConditionInput
  ) {
    deleteOptions(input: $input, condition: $condition) {
      id
      nomOption
      formuleId
      formule {
        id
        nomFormule
        brancheId
        branche {
          id
          nomBranche
          formules {
            items {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        options {
          items {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      regimes {
        items {
          id
          nomRegime
          optionId
          options {
            id
            nomOption
            formuleId
            formule {
              id
              nomFormule
              brancheId
              branche {
                id
                nomBranche
                formules {
                  items {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              options {
                items {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            regimes {
              items {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          champs {
            items {
              id
              nom
              valeur
              typeValeur
              regimeId
              regime {
                id
                nomRegime
                optionId
                options {
                  id
                  nomOption
                  formuleId
                  formule {
                    id
                    nomFormule
                    brancheId
                    branche {
                      id
                      nomBranche
                      formules {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    options {
                      items {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  regimes {
                    items {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                champs {
                  items {
                    id
                    nom
                    valeur
                    typeValeur
                    regimeId
                    regime {
                      id
                      nomRegime
                      optionId
                      options {
                        id
                        nomOption
                        formuleId
                        createdAt
                        updatedAt
                        __typename
                      }
                      champs {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegime = /* GraphQL */ `
  mutation CreateRegime(
    $input: CreateRegimeInput!
    $condition: ModelRegimeConditionInput
  ) {
    createRegime(input: $input, condition: $condition) {
      id
      nomRegime
      optionId
      options {
        id
        nomOption
        formuleId
        formule {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        regimes {
          items {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      champs {
        items {
          id
          nom
          valeur
          typeValeur
          regimeId
          regime {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegime = /* GraphQL */ `
  mutation UpdateRegime(
    $input: UpdateRegimeInput!
    $condition: ModelRegimeConditionInput
  ) {
    updateRegime(input: $input, condition: $condition) {
      id
      nomRegime
      optionId
      options {
        id
        nomOption
        formuleId
        formule {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        regimes {
          items {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      champs {
        items {
          id
          nom
          valeur
          typeValeur
          regimeId
          regime {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegime = /* GraphQL */ `
  mutation DeleteRegime(
    $input: DeleteRegimeInput!
    $condition: ModelRegimeConditionInput
  ) {
    deleteRegime(input: $input, condition: $condition) {
      id
      nomRegime
      optionId
      options {
        id
        nomOption
        formuleId
        formule {
          id
          nomFormule
          brancheId
          branche {
            id
            nomBranche
            formules {
              items {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          options {
            items {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        regimes {
          items {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      champs {
        items {
          id
          nom
          valeur
          typeValeur
          regimeId
          regime {
            id
            nomRegime
            optionId
            options {
              id
              nomOption
              formuleId
              formule {
                id
                nomFormule
                brancheId
                branche {
                  id
                  nomBranche
                  formules {
                    items {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                options {
                  items {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              regimes {
                items {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            champs {
              items {
                id
                nom
                valeur
                typeValeur
                regimeId
                regime {
                  id
                  nomRegime
                  optionId
                  options {
                    id
                    nomOption
                    formuleId
                    formule {
                      id
                      nomFormule
                      brancheId
                      branche {
                        id
                        nomBranche
                        createdAt
                        updatedAt
                        __typename
                      }
                      options {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    regimes {
                      items {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  champs {
                    items {
                      id
                      nom
                      valeur
                      typeValeur
                      regimeId
                      regime {
                        id
                        nomRegime
                        optionId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userId
      email
      password
      nom
      prenom
      civilite
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userId
      email
      password
      nom
      prenom
      civilite
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userId
      email
      password
      nom
      prenom
      civilite
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPmss = /* GraphQL */ `
  mutation CreatePmss(
    $input: CreatePmssInput!
    $condition: ModelPmssConditionInput
  ) {
    createPmss(input: $input, condition: $condition) {
      id
      valeur
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePmss = /* GraphQL */ `
  mutation UpdatePmss(
    $input: UpdatePmssInput!
    $condition: ModelPmssConditionInput
  ) {
    updatePmss(input: $input, condition: $condition) {
      id
      valeur
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePmss = /* GraphQL */ `
  mutation DeletePmss(
    $input: DeletePmssInput!
    $condition: ModelPmssConditionInput
  ) {
    deletePmss(input: $input, condition: $condition) {
      id
      valeur
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBatchPayload = /* GraphQL */ `
  mutation CreateBatchPayload(
    $input: CreateBatchPayloadInput!
    $condition: ModelBatchPayloadConditionInput
  ) {
    createBatchPayload(input: $input, condition: $condition) {
      count
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBatchPayload = /* GraphQL */ `
  mutation UpdateBatchPayload(
    $input: UpdateBatchPayloadInput!
    $condition: ModelBatchPayloadConditionInput
  ) {
    updateBatchPayload(input: $input, condition: $condition) {
      count
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBatchPayload = /* GraphQL */ `
  mutation DeleteBatchPayload(
    $input: DeleteBatchPayloadInput!
    $condition: ModelBatchPayloadConditionInput
  ) {
    deleteBatchPayload(input: $input, condition: $condition) {
      count
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
