import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './authentification/Login';
import BranchPage from './admin/handleBranch/BranchPage';
import ForgotPassword from './authentification/ForgotPassword';
import NewUser from './admin/handleUser/NewUser';
import UpdateUser from './admin/handleUser/UpdateUser';
import FromStatus from './client/FormStatus';
import FormUpdate from './client/FormUpdate';
import FormClient from './client/FormClient';
import PdfPage from './client/PdfPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BranchProvider } from './Context/BrancheContext';
import Choice from './authentification/Choice';
import AccountPage from './admin/AccountPage';
import NewBranch from './admin/handleBranch/NewBranch';
import UpdateBranche from './admin/handleBranch/UpdateBranch';
import YourProfile from './admin/YourProfile';
import axios from 'axios';
import ResetPasswordUsingVerificationCode from './authentification/ResetPasswordUsingVerificationCode';
import ConfirmSignUp from './authentification/ConfirmSignup';
import HelpPage from './components/componentsClient/HelpPage';
import SettingsPage from './admin/SettingsPage';
import { ConfigProvider } from './Context/ConfigContext';

axios.defaults.withCredentials = true;

function App() {
    return (
        <>
            <ConfigProvider>
                <BranchProvider>
                    <BrowserRouter>
                        <ToastContainer />
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/forgotPassword" element={<ForgotPassword />} />
                            <Route path="/confirmSignup" element={<ConfirmSignUp />} />
                            <Route
                                path="/resetUsingVerificationCode"
                                element={<ResetPasswordUsingVerificationCode />}
                            />
                            <Route path="/choice" element={<Choice />} />

                            <Route path="/status" element={<FromStatus />} />
                            <Route path="/updateStatus/:id" element={<FormUpdate />} />
                            <Route path="/formClient" element={<FormClient />} />
                            <Route path="/recapitulatif/PDF" element={<PdfPage />} />

                            <Route path="/accountUser" element={<AccountPage />} />
                            <Route path="/settings" element={<SettingsPage />} />
                            <Route path="/branch" element={<BranchPage />} />
                            <Route path="/newbranch" element={<NewBranch />} />
                            <Route path="/newUser" element={<NewUser />} />
                            <Route path="/updateUser" element={<UpdateUser />} />
                            <Route path="/updateBranche/:id" element={<UpdateBranche />} />
                            <Route path="/profile" element={<YourProfile />} />

                            <Route path="/help" element={<HelpPage />} />
                        </Routes>
                    </BrowserRouter>
                </BranchProvider>
            </ConfigProvider>
        </>
    );
}

export default App;
