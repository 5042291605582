import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../styles/stylesAdmin/FormUser.css';
import { createUser, updateUser } from '../../graphql/mutations';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { getUser, listUsers } from '../../graphql/queries';
import AWS from 'aws-sdk';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql/lib/types';

const initialState = {
    id: '',
    userId: '',
    email: '',
    password: '',
    nom: '',
    prenom: '',
    civilite: '',
    role: '',
    createdAt: '',
    updatedAt: '',
    adresse: '',
    ville: '',
    cp: ''
};

function FormUser({ type }) {
    const { state } = useLocation();

    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialState);
    const [userGroup, setUserGroup] = useState(null);

    useEffect(() => {
        if (type === 'edit') {
            const userId = state?.user?.id;
            // Récupération des données de l'utilisateur pour modification
            const fetchUserData = async () => {
                try {
                    if (userId) {
                        const result = await API.graphql(graphqlOperation(getUser, { id: userId }));
                        if (result.data.getUser) {
                            setFormData(result.data.getUser);
                        }
                    }
                } catch (error) {
                    toast.error("Erreur lors de la récupération des données de l'utilisateur.", error);
                    console.error('Erreur lors de la récupération des données de l’utilisateur:', error);
                }
            };
            fetchUserData();
        }

        const fetchUserGroup = async () => {
            try {
                const session = await Auth.currentSession();
                const groups = session.getIdToken().payload['cognito:groups'] || [];
                setUserGroup(groups.length ? groups[0] : null);
            } catch (error) {
                console.error('Erreur lors de la récupération du groupe utilisateur: ', error);
            }
        };
        fetchUserGroup();
    }, [type]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (type === 'create') {
            await handleCreate();
        } else if (type === 'edit') {
            await handleEdit();
        }
    };

    const handleBack = () => {
        navigate('/accountUser');
    };

    const checkEmailExists = async (email) => {
        try {
            const usersData = await API.graphql(graphqlOperation(listUsers, { filter: { email: { eq: email } } }), {
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            });
            return usersData.data.listUsers.items.length > 0;
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la vérification de l'email.");
            console.error("Erreur lors de la vérification de l'email:", error);
            throw error;
        }
    };

    const uniquePasswordGeneration = () => {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let randomstring = '';
        for (let i = 0; i < 8; i++) {
            randomstring += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return randomstring;
    };

    const handleCreate = async () => {
        try {
            // const requiredFields = ['email', 'nom', 'prenom', 'civilite', 'adresse', 'ville', 'cp'];
            // const emptyFields = requiredFields.filter((field) => !formData[field]?.trim());

            // if (emptyFields.length > 0) {
            //     toast.error(`Veuillez remplir les champs obligatoires: ${emptyFields.join(', ')}`);
            //     return;
            // }

            const emailExists = await checkEmailExists(formData.email);
            if (emailExists) {
                toast.error('Cette adresse email est déjà utilisée.');
                return;
            }

            const validRoles = ['ADMIN', 'GESTIONNAIRE', 'COMMERCIAL'];

            if (!validRoles.includes(formData.role.toUpperCase())) {
                toast.error('Rôle spécifié non valide.');
                return;
            }

            // Vérification que seul un ADMIN peut créer un autre ADMIN
            if (formData.role.toUpperCase() === 'ADMIN' && userGroup !== 'ADMIN') {
                toast.error("Seul un utilisateur du groupe 'Admin' peut créer un autre utilisateur Admin.");
                return;
            }

            const randomPassword = uniquePasswordGeneration();
            const { user } = await Auth.signUp({
                username: formData.email,
                password: randomPassword,
                attributes: { email: formData.email }
            });

            // Ajout à la base de données
            await API.graphql(
                graphqlOperation(createUser, {
                    input: {
                        userId: user.username,
                        password: randomPassword,
                        email: formData.email,
                        nom: formData.nom,
                        prenom: formData.prenom,
                        civilite: formData.civilite,
                        role: formData.role.toUpperCase()
                    }
                })
            );

            await updateUserGroup(formData.email, formData.role);

            toast.success('Utilisateur créé avec succès !');
            console.log('Utilisateur créé avec succès !');
            navigate('/accountUser');
        } catch (error) {
            toast.error(`Erreur lors de la création de l'utilisateur : ${error.message}`);
            console.error("Erreur lors de la création de l'utilisateur : ", error);
        }
    };

    const handleEdit = async () => {
        try {
            await API.graphql(
                graphqlOperation(updateUser, {
                    input: {
                        id: formData.id,
                        email: formData.email,
                        nom: formData.nom,
                        prenom: formData.prenom,
                        civilite: formData.civilite,
                        role: formData.role.toUpperCase()
                    }
                })
            );

            // Mise à jour des groupes Cognito si nécessaire
            await updateUserGroup(formData.email, formData.role);

            toast.success("Mise à jour de l'utilisateur réussie!");
            console.log("Mise à jour l'utilisateur réussie !");
            navigate('/accountUser');
        } catch (error) {
            toast.error("Erreur lors de la mise à jour de l'utilisateur.");
            console.error('Erreur lors de la mise à jour:', error);
            console.error('Données du formData au moment de l’erreur:', formData);
        }
    };

    const updateUserGroup = async (email, newGroup) => {
        try {
            await Auth.currentCredentials().then((credentials) => {
                AWS.config.update({
                    region: 'us-east-1',
                    credentials: Auth.essentialCredentials(credentials)
                });
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();
            const userPoolId = 'us-east-1_WKb9RaHPH';

            const listGroupsResponse = await cognito
                .adminListGroupsForUser({
                    UserPoolId: userPoolId,
                    Username: email
                })
                .promise();

            const currentGroups = listGroupsResponse.Groups.map((group) => group.GroupName);

            for (const group of currentGroups) {
                if (group !== newGroup) {
                    await cognito
                        .adminRemoveUserFromGroup({
                            UserPoolId: userPoolId,
                            Username: email,
                            GroupName: group
                        })
                        .promise();
                }
            }

            if (!currentGroups.includes(newGroup)) {
                await cognito
                    .adminAddUserToGroup({
                        UserPoolId: userPoolId,
                        Username: email,
                        GroupName: newGroup
                    })
                    .promise();
            }
        } catch (error) {
            toast.error("Erreur lors de la mise à jour du groupe de l'utilisateur.");
            console.error("Erreur lors de la mise à jour du groupe de l'utilisateur:", error);
        }
    };

    return (
        <div className="formUser-container">
            <form onSubmit={handleSubmit} className="formUser-body">
                <div>
                    <button className="formUser-btn-back" onClick={handleBack}>
                        <ArrowBackIcon />
                    </button>
                </div>
                <h1 className="formUser-title">
                    {type === 'edit' ? "Modifier l'utilisateur" : 'Créer un utilisateur'}
                </h1>
                <label className="formUser-label">Civilité :</label>
                <select
                    className="formUser-select"
                    name="civilite"
                    value={formData.civilite || ''}
                    onChange={handleChange}
                >
                    <option value="" disabled>
                        --
                    </option>
                    <option value="mr">Monsieur</option>
                    <option value="mme">Madame</option>
                </select>

                <label className="formUser-label">Prénom :</label>
                <input
                    className="formUser-input"
                    placeholder="Modifier le prénom"
                    type="text"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleChange}
                />
                <label className="formUser-label">Nom :</label>
                <input
                    className="formUser-input"
                    placeholder="Modifier le nom"
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                />
                {type === 'create' && (
                    <>
                        <label className="formUser-label">Email :</label>
                        <input
                            className="formUser-input"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                        />

                        <label className="formUser-label">Adresse :</label>
                        <input
                            className="formUser-input"
                            type="text"
                            name="adresse"
                            value={formData.adresse}
                            onChange={handleChange}
                            placeholder="Adresse"
                        />

                        <div className="formUser-center">
                            <div className="formUser-block">
                                <label className="formUser-label">Code postal :</label>
                                <input
                                    className="formUser-input"
                                    placeholder="Saisir le code postal "
                                    type="text"
                                    maxLength={5}
                                    name="cp"
                                    value={formData.cp}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="formUser-block">
                                <label className="formUser-label">Ville :</label>
                                <input
                                    className="formUser-input formUser-input-large"
                                    placeholder="Saisir la ville "
                                    type="text"
                                    name="ville"
                                    value={formData.ville}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </>
                )}

                <label className="formUser-label">Rôle :</label>
                <select className="formUser-select" name="role" value={formData.role} onChange={handleChange}>
                    <option value="">Choisir un rôle</option>
                    {userGroup === 'ADMIN' && <option value="ADMIN">Admin</option>}
                    <option value="GESTIONNAIRE">Gestionnaire</option>
                    <option value="COMMERCIAL">Commercial</option>
                </select>
                <button className="formUser-btn" type="submit">
                    {type === 'edit' ? 'Enregistrer les modifications' : 'Créer un utilisateur'}
                </button>
            </form>
        </div>
    );
}

export default FormUser;
