import '../../styles/stylesAdmin/FormUser.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormUser from '../../components/componentsAdmin/FormUser';
import Navbar from '../../components/componentsAdmin/Navbar';

function NewUser() {
    return (
        <>
            <Navbar />
            <div className="formUser">
                <ToastContainer />
                <FormUser type="create" />
            </div>
        </>
    );
}

export default NewUser;
