import '../../styles/stylesAdmin/FormUser.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormUser from '../../components/componentsAdmin/FormUser';
import Navbar from '../../components/componentsAdmin/Navbar';

function UpdateUser() {

    return (
        <>
            <Navbar />
            <div className="formUser">
                <ToastContainer />
                <FormUser
                    type="edit"
                />
            </div>
        </>
    );
}

export default UpdateUser;
