import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { listUsers } from '../../graphql/queries';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { deleteUser, updateUser } from '../../graphql/mutations';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql/lib/types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from 'aws-sdk'; // Import AWS SDK
import awsExports from '../../aws-exports';

const styles = {
    paper: {
        height: '330px',
        width: '97%',
        padding: 20,
        maxHeight: '340px'
    }
};

export default function TableUsers() {
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [searchText, setSearchText] = useState('');
    const [userGroup, setUserGroup] = useState(null);

    const fetchUserGroup = async () => {
        try {
            const session = await Auth.currentSession();
            const groups = session.getIdToken().payload['cognito:groups'] || [];
            if (groups.length) {
                setUserGroup(groups[0]);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du groupe de l'utilisateur: ", error);
        }
    };

    const fetchData = async () => {
        try {
            const usersData = await API.graphql(graphqlOperation(listUsers), { authMode: GRAPHQL_AUTH_MODE.API_KEY });
            setUsers(usersData.data.listUsers.items);
        } catch (error) {
            console.error('Erreur lors de la récupération des données des Users: ', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchUserGroup();
    }, [API, Auth]);

    useEffect(() => {
        const filteredUsers = users.filter((user) => {
            if (userGroup !== 'ADMIN' && user.role === 'ADMIN') return false;

            const searchRegex = new RegExp(searchText, 'i');
            return (
                searchRegex.test(user.prenom) ||
                searchRegex.test(user.nom) ||
                searchRegex.test(user.email) ||
                searchRegex.test(user.role)
            );
        });

        const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setDisplayedUsers(paginatedUsers);
    }, [users, searchText, userGroup, page, rowsPerPage]);

    const handleEditUser = (e, user) => {
        navigate('/updateUser', { state: { user: user } });
    };

    const handleDeleteUser = async (e, user) => {
        if (userGroup !== 'ADMIN') {
            alert('Vous devez contacter votre administrateur pour supprimer un utilisateur.');
            return;
        }

        const confirmDelete = window.confirm(
            `Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.prenom} ${user.nom}?`
        );
        if (!confirmDelete) {
            return;
        }

        try {
            await Auth.currentCredentials().then((credentials) => {
                AWS.config.update({
                    region: 'us-east-1',
                    credentials: Auth.essentialCredentials(credentials)
                });
            });
            
            const cognitoProvider = new AWS.CognitoIdentityServiceProvider({
                apiVersion: '2016-04-18',
                region: awsExports.aws_project_region
            });

            const params = {
                UserPoolId: awsExports.aws_user_pools_id,
                Username: user.email
            };

            await cognitoProvider.adminDeleteUser(params).promise();

            await API.graphql(graphqlOperation(deleteUser, { input: { id: user.id } }));

            const updatedUsers = users.filter((u) => u.id !== user.id);
            setUsers(updatedUsers);
            setDisplayedUsers(updatedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
            setPage(0);

            toast.success(`L'utilisateur ${user.prenom} ${user.nom} a été supprimé avec succès.`);
        } catch (error) {
            console.error("Erreur lors de la suppression de l'utilisateur :", error);
            toast.error("Une erreur est survenue lors de la tentative de suppression de l'utilisateur.");
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        const displayedUsers = users
            .filter((user) => {
                const searchRegex = new RegExp(searchText, 'i');
                return (
                    searchRegex.test(user.prenom) ||
                    searchRegex.test(user.nom) ||
                    searchRegex.test(user.email) ||
                    searchRegex.test(user.role)
                );
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        setDisplayedUsers(displayedUsers);
        setPage(0);
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 0' }}>
                <TextField
                    label="Rechercher"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={handleSearchTextChange}
                />
            </div>
            <div>
                <TableContainer component={Paper} style={styles.paper}>
                    <ToastContainer />
                    <Table aria-label="user table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Rôle</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedUsers.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.prenom}</TableCell>
                                    <TableCell>{user.nom}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>
                                        <MenuItem
                                            id={'edit-menu-' + user.id}
                                            onClick={(e) => handleEditUser(e, user)}
                                            style={{ width: '75%', justifyContent: 'center' }}
                                        >
                                            <EditIcon />
                                        </MenuItem>
                                    </TableCell>
                                    <TableCell>
                                        <MenuItem
                                            id={'delete-menu-' + user.id}
                                            onClick={(e) => handleDeleteUser(e, user)}
                                            style={{ width: '75%', justifyContent: 'center' }}
                                        >
                                            <DeleteIcon />
                                        </MenuItem>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[4, 10, 25]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
}
